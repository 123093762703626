<template>
  <div class="goods">
    <el-row :gutter="20">
      <el-col :span="12">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>基础信息</span>
          </div>
          <div class="form-item-wrap">
            <div class="form-item-title">商品标题</div>
            <el-input v-model="goodsform.title" size="small"></el-input>
          </div>
          <div class="form-item-wrap">
            <div class="form-item-title">商品描述（选填）</div>
            <el-input type="textarea" v-model="goodsform.description" size="small"></el-input>
          </div>
        </el-card>
        <el-row :gutter="15">
          <el-col :span="16">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>上下架管理</span>
              </div>
              <el-row :gutter="20">
                <el-col :span="9">
                  <div class="form-item-wrap">
                  <div class="form-item-title">定时上架时间</div>
                    <el-date-picker type="datetime" placeholder="选择上架日期" size="small" v-model="goodsform.begin_time" style="width: 100%;" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
                  </div>
                </el-col>
                <el-col :span="9">
                  <div class="form-item-wrap">
                  <div class="form-item-title">定时下架时间</div>
                    <el-date-picker type="datetime" placeholder="选择上架日期" size="small" v-model="goodsform.end_time" style="width: 100%;" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="form-item-wrap">
                  <div class="form-item-title">上下架开关（绝对）</div>
                    <el-switch
                      v-model="goodsform.status"
                      active-color="#13ce66"
                      inactive-color="#ff4949"
                      :active-value="1"
                      :inactive-value="2"
                      active-text="上架"
                      inactive-text="下架">
                    </el-switch>
                  </div>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
          <el-col :span="8">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>首图</span>
              </div>
              <el-row :gutter="5">
                <el-col :span="18">
                  <el-upload
                    action="admin/goods/images"
                    :show-file-list = false
                    accept="image/png, image/jpeg, image/gif"
                    :on-success="handleHeadImageSuccess">
                    <el-button size="mini" type="primary">点击上传</el-button>
                    <div slot="tip" class="el-upload__tip"><p class="image-notice">图片不超过500kb，<br>且只能是jpg/png文件</p></div>
                  </el-upload>
                </el-col>
                <el-col :span="6">
                  <i v-if="goodsform.image==null" class="el-icon-picture-outline"></i>
                  <img v-else style="width: 55px; height: 55px" :src="goodsform.image">
                </el-col>
              </el-row>
            </el-card>
          </el-col>
        </el-row>
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>主图(总共能上传10张主图)</span>
          </div>
          <div class="form-item-wrap">
            <el-upload
              action="admin/goods/images"
              list-type="picture-card"
              accept="image/png, image/jpeg, image/gif"
              :file-list="fileList"
              multiple
              :on-preview="handlePhotoCardPreview"
              :on-remove="handlePhotoRemove"
              :on-success="handlePhotoSuccess"
              :on-exceed="handleExceed"
              :limit="10">
              <i class="el-icon-plus"></i>
            </el-upload>
          </div>
        </el-card>
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>商品详情</span>
          </div>
          <div id="detail"></div>
        </el-card>
        <el-button type="primary" @click="submit">发布</el-button>
      </el-col>
      <el-col :span="12">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>属性管理</span>
          </div>
          <div class="form-item-wrap">
            <div class="form-item-title">商品属性</div>
              <el-row :gutter="10" style="margin:0;">
                <el-col :span="8">
                  <div class="attr-name">尺寸组合</div>
                  <el-row v-for="(item,index) in attr.attr1" :key="index" class="attr-item">
                    <el-col :span="18">
                      <el-input v-model="item.name" size="small" clearable></el-input>
                    </el-col>
                    <el-col :span="6" class="t-right">
                      <el-button icon="el-icon-delete" size="small" @click="deleteAttr('attr1',index)" :disabled="index === 0"></el-button>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24"><el-button type="text" size="medium" @click="addAttr('attr1')"><i class="el-icon-circle-plus-outline"></i>新增属性</el-button></el-col>
                  </el-row>
                </el-col>
                <el-col :span="8">
                  <div class="attr-name">颜色</div>
                  <el-row v-for="(item,index) in attr.attr2" :key="item.index" class="attr-item">
                    <el-col :span="18">
                      <el-input size="small" v-model="item.name" clearable></el-input>
                    </el-col>
                    <el-col :span="6" class="t-right">
                      <el-button size="small" icon="el-icon-delete"  @click="deleteAttr('attr2',index)" :disabled="index === 0"></el-button>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24"><el-button type="text" size="medium" @click="addAttr('attr2')"><i class="el-icon-circle-plus-outline"></i>新增属性</el-button></el-col>
                  </el-row>
                </el-col>
                <el-col :span="8">
                  <div class="attr-name">材料</div>
                  <el-row v-for="(item,index) in attr.attr3" :key="item.index" class="attr-item">
                    <el-col :span="18">
                        <el-input size="small" v-model="item.name" clearable></el-input>
                    </el-col>
                    <el-col :span="6" class="t-right">
                      <el-button size="small" icon="el-icon-delete" @click="deleteAttr('attr3',index)" :disabled="index === 0"></el-button>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24"><el-button type="text" size="medium" @click="addAttr('attr3')"><i class="el-icon-circle-plus-outline"></i>新增属性</el-button></el-col>
                  </el-row>
                </el-col>
              </el-row>
          </div>
        </el-card>
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>商品属性值</span>
          </div>
          <div class="form-item-wrap">
            <el-row>
              <el-col>
                <el-row class="attr-name">
                  <el-col :span="4">图片</el-col>
                  <el-col :span="4">属性一</el-col>
                  <el-col :span="4">属性二</el-col>
                  <el-col :span="4">属性三</el-col>
                  <el-col :span="4">库存</el-col>
                  <el-col :span="4">价格</el-col>
                </el-row>
                <el-row v-for="(attr, index) in goodsform.attrsList" :key="index" class="attrslist" type="flex">
                  <el-col :span="4">
                    <div class="property_upload_image_wrap">
                      <i v-if="attr.image === ''" class="el-icon-picture property_upload_icon"></i>
                      <img v-else :src="attr.image" class="property_upload_image">
                      <input type="file" class="property_file_input" accept="image/png,image/gif,image/jpeg" :data-tar="index" @change="handlePhotoChange">
                    </div>
                  </el-col>
                  <el-col :span="4">{{attr.attr1}}</el-col>
                  <el-col :span="4">{{attr.attr2}}</el-col>
                  <el-col :span="4">{{attr.attr3}}</el-col>
                  <el-col :span="4">
                    <el-input-number size="mini" v-model="attr.stock" :min="0" ></el-input-number>
                  </el-col>
                  <el-col :span="4">
                    <el-input-number size="mini" v-model="attr.price" :precision="2" :step="1" :min="0"></el-input-number>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </el-card>
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>商品参数</span>
          </div>
          <div class="form-item-wrap">
            <el-row :gutter="10">
              <el-col :span="8" v-for="(item, index) in goodsform.param" :key="index">
                <el-row :gutter="10" class="mgb10">
                  <el-col :span="8"><el-input v-model="item.name" size="small" placeholder="参数名"></el-input></el-col>
                  <el-col :span="10"><el-input v-model="item.value" size="small" placeholder="参数值"></el-input></el-col>
                  <el-col :span="5"><el-button icon="el-icon-delete" size="small" @click="deleteParam(index)"></el-button></el-col>
                </el-row>
              </el-col>
              <el-button type="text" @click="addParam"><i class="el-icon-circle-plus-outline"></i>添加参数</el-button>
            </el-row>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-dialog :visible.sync="dialogPhotoVisible" width="20%">
      <img style="width: 100%" :src="dialogPhotoUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
import WangEditor from 'wangeditor'
export default {
  name: 'Goods',
  data () {
    return {
      editor: null,
      fileList: [],
      dialogPhotoUrl: '',
      dialogPhotoVisible: false,
      goodsform: {},
      attr: {
        attr1: [{ name: '默认' }],
        attr2: [{ name: '默认' }],
        attr3: [{ name: '默认' }]
      }
    }
  },
  methods: {
    submit () {
      var that = this
      if (this.goodsform.title.trim().length < 1 || this.goodsform.image == null) {
        this.$message.error('错了哦，标题和首图是必填项')
      } else {
        this.axios.post('admin/goods/save', this.goodsform)
          .then(function (response) {
            if (response.data.code === 0) {
              that.$message.success('商品添加成功！')
              that.fileList = [] // 清空主图列表
              that.initForm() // 初始化表单
              that.initAttr() // 初始化属性
              that.editor.txt.clear() // 初始化富文本编辑器
            }
            if (response.data.code === 1) {
              that.$message.error(response.data.msg)
            }
          })
      }
    },
    initAttr () {
      this.attr = {
        attr1: [{ name: '默认' }],
        attr2: [{ name: '默认' }],
        attr3: [{ name: '默认' }]
      }
    },
    initForm () {
      this.goodsform = {
        title: '',
        description: '',
        begin_time: '',
        end_time: '',
        status: 1,
        attrsList: [{ attr1: '默认', attr2: '默认', attr3: '默认', stock: 99, price: 0, image: '' }],
        image: null,
        images: [],
        param: [],
        content: ''
      }
    },
    handleHeadImageSuccess (response, file, fileList) {
      this.goodsform.image = response
    },
    // 属性组合配图
    handlePhotoChange (e) {
      var that = this
      var file = e.target.files[0]
      var tar = e.target.dataset.tar
      var param = new FormData()
      param.append('image', file)
      this.axios.post('admin/goods/image', param)
        .then(function (response) {
          if (response.data.code === 0) {
            that.goodsform.attrsList[tar].image = response.data.url
          }
        })
    },
    // 主图删除
    handlePhotoRemove (file, fileList) {
      var temp = []
      this.goodsform.images = []
      fileList.forEach(function (image, index) {
        temp[index] = image.response
      })
      this.goodsform.images = temp
    },
    // 主图上传成功
    handlePhotoSuccess (response, file, fileList) {
      var temp = []
      this.goodsform.images = []
      fileList.forEach(function (image, index) {
        temp[index] = image.response
      })
      this.goodsform.images = temp
    },
    // 主图预览
    handlePhotoCardPreview (file) {
      this.dialogPhotoUrl = file.url
      this.dialogPhotoVisible = true
    },
    // 主图数量控制
    handleExceed (files, fileList) {
      this.$message.warning(`当前限制选择 10 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    addParam () {
      this.goodsform.param.push({ name: '', value: '' })
    },
    deleteParam (index) {
      this.goodsform.param.splice(index, 1)
    },
    addAttr (tar) {
      this.attr[tar].push({ name: '' })
    },
    deleteAttr (tar, index) {
      this.attr[tar].splice(index, 1)
    },
    initWangEditor () {
      const editor = new WangEditor('#detail')
      // 配置 onchange 回调函数，将数据同步到 vue 中
      editor.config.onchange = (newHtml) => {
        this.goodsform.content = newHtml
      }
      editor.config.uploadImgServer = 'admin/goods/imageineditor'
      editor.config.zIndex = 100
      editor.config.pasteIgnoreImg = true
      editor.config.menus = [
        'bold',
        'head',
        'fontSize',
        'italic',
        'underline',
        'strikeThrough',
        'foreColor',
        'justify',
        'image',
        'table',
        'splitLine',
        'undo',
        'redo'
      ]
      // 创建编辑器
      editor.create()
      this.editor = editor
    }
  },
  watch: {
    attrs: function (newValue, oldValue) {
      this.goodsform.attrsList = newValue
    }
  },
  computed: {
    attrs: function () {
      var attr1 = []
      var attr2 = []
      var attr3 = []
      var attr = []
      for (let i = 0; i < this.attr.attr1.length; i++) {
        if (this.attr.attr1[i].name !== '') {
          attr1.push(this.attr.attr1[i].name)
        }
      }
      for (let j = 0; j < this.attr.attr2.length; j++) {
        if (this.attr.attr2[j].name !== '') {
          attr2.push(this.attr.attr2[j].name)
        }
      }
      for (let k = 0; k < this.attr.attr3.length; k++) {
        if (this.attr.attr3[k].name !== '') {
          attr3.push(this.attr.attr3[k].name)
        }
      }
      for (let i = 0; i < attr1.length; i++) {
        for (let j = 0; j < attr2.length; j++) {
          for (let k = 0; k < attr3.length; k++) {
            attr.push({ attr1: attr1[i], attr2: attr2[j], attr3: attr3[k], stock: 99, price: 0, image: '' })
          }
        }
      }
      return attr
    }
  },
  mounted () {
    this.initForm()
    this.initAttr()
    this.initWangEditor()
  },
  beforeDestroy () {
    // 调用销毁 API 对当前编辑器实例进行销毁
    this.editor.destroy()
    this.editor = null
  }
}
</script>

<style scoped>
.t-right{
  text-align: right;
}
.attrslist{
  margin-bottom: 10px;
  color: #a5a5a593;
  font-size: 0.9em;
}
.property_upload_image{
  width: 30px;
  height: 30px;
  position: relative;
}
.property_upload_icon {
  color: #a5a5a5;
  font-size: 25px;
  line-height: 25px;
}
.box-card{
  margin-bottom: 20px;
}
.form-item-wrap{
  margin-bottom: 10px;
}
.form-item-title{
  color: #8a8a8a;
  margin-bottom: 10px;
  font-size: 0.8em;
}
.attr-name{
  color: #a5a5a5;
  font-weight: bold;
  font-size: 0.9em;
  margin-bottom: 10px;
}
.attr-item{
  margin-bottom: 10px;
}
.mgb10{
  margin-bottom: 10px;
}
.property_file_input{
  position: absolute;
  left: 0;
  width: 25px;
  height: 25px;
  opacity: 0;
  font-size: 0;
  cursor: pointer;
}
.image-notice{
  font-size: 10px;
  line-height: 17px;
  color: #a5a5a5;
  margin: 0;
}
</style>
